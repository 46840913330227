import {
  HydrateWhenInViewport,
  Link,
  PageContainer,
  Paragraph,
  StandardInput,
  Title,
  TriggerEvent,
} from '@loveholidays/design-system';
import { Translation, useTranslation } from '@loveholidays/phrasebook';
import React, { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { useMutation } from 'urql';

import { NewsletterImages } from './NewsletterImages';
import subscribeQuery from './subscribe.gql';
import { Mutation } from '@AuroraTypes';
import { ClassNameProps } from '@ComponentProps';
import { Button } from '@Components/Button/Button';
import { SocialProfiles, SocialProfilesProps } from '@Components/SocialProfiles/SocialProfiles';
import { SocialProfilesWrapper } from '@Components/SocialProfiles/SocialProfilesWrapper';
import { useAppContext } from '@Contexts/contexts';
import { captureClientError } from '@Core/errors/errors';
import { isValidMarketingPreferencesUpdate } from '@Core/marketingPreferences/util';
import { SubscriptionName, SubscriptionSource } from '@Core/subscriptions/types';
import { useSubscriptionTracking } from '@Core/tracking/hooks/useSubscriptionTracking';
import { SubscriptionEvent } from '@Core/tracking/types';
import { isUnionError } from '@Stores/checkout/utils';

export interface NewsletterProps extends ClassNameProps {
  socialProfiles?: SocialProfilesProps['items'];
  emailRegex: string;
}

const Newsletter: React.FC<NewsletterProps> = ({ className, socialProfiles, emailRegex }) => {
  const [email, setEmail] = useState('');
  const [showInvalidEmailError, setShowInvalidEmailError] = useState(false);
  const [{ data, fetching }, performSubscribe] = useMutation<Mutation>(subscribeQuery);
  const track = useSubscriptionTracking();
  const { t } = useTranslation();
  const {
    site: {
      coreLinks: { privacyPolicy },
      siteCode,
    },
  } = useAppContext();

  if (!privacyPolicy.url) {
    captureClientError(new Error(`privacyPolicy.url undefined in Newsletter for ${siteCode}`));
  }

  const isValidEmail = useMemo(() => new RegExp(emailRegex).test(email), [email, emailRegex]);

  useEffect(() => {
    if (isValidEmail) {
      setShowInvalidEmailError(false);
    }
  }, [isValidEmail]);

  const onSubmit = useCallback(
    async (e: React.FormEvent<HTMLFormElement> | TriggerEvent) => {
      e.preventDefault();

      if (!isValidEmail) {
        setShowInvalidEmailError(true);

        return;
      }

      try {
        await performSubscribe({
          channel: 'email',
          email,
          source: SubscriptionSource.NEWSLETTER_SUBSCRIBE,
        });
        const subscribeResponse = data?.User.subscribe;

        if (isValidMarketingPreferencesUpdate(subscribeResponse)) {
          track(
            SubscriptionName.MARKETING,
            SubscriptionSource.NEWSLETTER_SUBSCRIBE,
            SubscriptionEvent.optedIn,
          );
        }
      } catch (e) {
        captureClientError(e as Error);
      }
    },
    [data?.User.subscribe, email, isValidEmail, performSubscribe, track],
  );

  const getInputErrorMessage = () => {
    if (showInvalidEmailError) {
      return t('email.error');
    }
    if (isUnionError(data?.User.subscribe) && data?.User.subscribe.code === '401') {
      return t('newsletter.logInToSubscribe');
    }

    return undefined;
  };

  return (
    <HydrateWhenInViewport>
      <section
        className={className}
        sx={{ backgroundColor: 'backgroundLightsubtle' }}
        id="newsletter-signup"
      >
        <PageContainer>
          <div
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              paddingY: ['3xl', null, '4xl'],
              paddingX: 'xs',
            }}
          >
            <div>
              {!isValidMarketingPreferencesUpdate(data?.User.subscribe) ? (
                <Fragment>
                  <Title
                    variant="medium"
                    as="h2"
                    sx={{
                      marginBottom: '3xs',
                    }}
                  >
                    {t('newsletter.deals')}
                  </Title>
                  <Paragraph
                    variant="large"
                    sx={{
                      marginBottom: ['2xl', 'xl', '3xl'],
                      color: 'textDimmedheavy',
                    }}
                  >
                    {t('newsletter.weeklyInbox')}
                  </Paragraph>
                  <form onSubmit={onSubmit}>
                    <div
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        flexWrap: 'wrap',
                        alignItems: 'start',
                      }}
                    >
                      <div
                        sx={{
                          verticalAlign: 'top',
                          width: '100%',
                        }}
                      >
                        <StandardInput
                          variant="48"
                          placeholder={t('email.enter')}
                          type="email"
                          name="email"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          errorMessage={getInputErrorMessage()}
                          required
                          sx={{
                            verticalAlign: 'top',
                            display: ['block', 'inline-block'],
                            width: ['100%', '320px'],
                            marginBottom: ['3xs', '2xs'],
                          }}
                        />
                        <Button
                          variant="Primary"
                          size="48"
                          onClick={(e) => onSubmit(e)}
                          disabled={showInvalidEmailError || fetching}
                          sx={{
                            verticalAlign: 'top',
                            display: 'inline-block',
                            marginBottom: ['4xs', '2xs'],
                            whiteSpace: 'nowrap',
                            marginLeft: [0, '2xs'],
                            minWidth: ['40%', 'auto'],
                          }}
                        >
                          {t('signUp')}
                        </Button>
                      </div>
                      <Paragraph
                        variant="extrasmall"
                        sx={{
                          color: 'textDimmedheavy',
                          order: [2, 3],
                          marginTop: ['xs', '2xs'],
                          marginBottom: ['2xl', '3xl'],
                        }}
                      >
                        <Translation
                          translationKey="ourPrivacyPolicy"
                          components={[
                            (text) => (
                              <Link
                                href={privacyPolicy.url ?? '/about-us/privacy-policy.html'}
                                target="_blank"
                                rel="noopener noreferrer"
                                sx={{
                                  '&, :visited': { color: 'textDimmedheavy' },
                                  ':hover': { color: 'textDefault' },
                                }}
                              >
                                {text}
                              </Link>
                            ),
                          ]}
                        />
                      </Paragraph>
                    </div>
                  </form>
                </Fragment>
              ) : (
                <Fragment>
                  <Title
                    variant="medium"
                    as="h1"
                    sx={{
                      marginBottom: '3xs',
                    }}
                  >
                    {t('newsletter.thankYou')}
                  </Title>
                  <Paragraph
                    variant="large"
                    sx={{
                      marginBottom: '2xl',
                      color: 'textDimmedheavy',
                    }}
                  >
                    {t('newsletter.lookingForward')}
                  </Paragraph>
                </Fragment>
              )}
              {socialProfiles ? (
                <SocialProfiles items={socialProfiles} />
              ) : (
                <SocialProfilesWrapper />
              )}
            </div>
            <NewsletterImages
              sx={{
                display: ['none', null, 'flex'],
                width: '60%',
                marginLeft: '3xs',
                justifyContent: ['none', null, 'center'],
              }}
            />
          </div>
        </PageContainer>
      </section>
    </HydrateWhenInViewport>
  );
};

// eslint-disable-next-line import/no-default-export
export default Newsletter;
